//Login Actions 
export const LOGIN_INITIAL = 'LOGIN_INITIAL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL'

//get partnerList
export const GET_PARTNER_LIST_INITIAL ="GET_PARTNER_LIST_INITIAL"
export const GET_PARTNER_LIST_SUCCESS ="GET_PARTNER_LIST_SUCCESS"
export const GET_PARTNER_LIST_FAIL ="GET_PARTNER_LIST_FAIL"
export const GET_PARTNER_LIST_NETWORK_ERROR="GET_PARTNER_LIST_NETWORK_ERROR"

//ADD partnerList
export const ADD_PARTNER_LIST_INITIAL ="ADD_PARTNER_LIST_INITIAL"
export const ADD_PARTNER_LIST_SUCCESS ="ADD_PARTNER_LIST_SUCCESS"
export const ADD_PARTNER_LIST_FAIL ="ADD_PARTNER_LIST_FAIL"
export const ADD_PARTNER_LIST_NETWORK_ERROR="ADD_PARTNER_LIST_NETWORK_ERROR"

//GET SUBMISSION DETAILS
export const GET_SUBMISSION_DETAILS_INITIAL ="GET_SUBMISSION_DETAILS_INITIAL"
export const GET_SUBMISSION_DETAILS_SUCCESS ="GET_SUBMISSION_DETAILS_SUCCESS"
export const GET_SUBMISSION_DETAILS_FAIL ="GET_SUBMISSION_DETAILS_FAIL"
export const GET_SUBMISSION_DETAILS_NETWORK_ERROR="GET_SUBMISSION_DETAILS_NETWORK_ERROR"

//GET form list
export const GET_FORM_LIST_INITIAL ="GET_FORM_LIST_INITIAL"
export const GET_FORM_LIST_SUCCESS ="GET_FORM_LIST_SUCCESS"
export const GET_FORM_LIST_FAIL ="GET_FORM_LIST_FAIL"
export const GET_FORM_LIST_NETWORK_ERROR="GET_FORM_LIST_NETWORK_ERROR"

//GET user submission form list
export const GET_FORM_SUBMISSION_DETAILS_INITIAL ="GET_FORM_SUBMISSION_DETAILS_INITIAL"
export const GET_FORM_SUBMISSION_DETAILS_SUCCESS ="GET_FORM_SUBMISSION_DETAILS_SUCCESS"
export const GET_FORM_SUBMISSION_DETAILS_FAIL ="GET_FORM_SUBMISSION_DETAILS_FAIL"
export const GET_FORM_SUBMISSION_DETAILS_NETWORK_ERROR="GET_FORM_SUBMISSION_DETAILS_NETWORK_ERROR"


//GET SAVE submission form RESPONSE
export const GET_SAVE_FORM_SUBMISSION_RESPONSE_INITIAL ="GET_SAVE_FORM_SUBMISSION_RESPONSE_INITIAL"
export const GET_SAVE_FORM_SUBMISSION_RESPONSE_SUCCESS ="GET_SAVE_FORM_SUBMISSION_RESPONSE_SUCCESS"
export const GET_SAVE_FORM_SUBMISSION_RESPONSE_FAIL ="GET_SAVE_FORM_SUBMISSION_RESPONSE_FAIL"
export const GET_SAVE_FORM_SUBMISSION_RESPONSE_NETWORK_ERROR="GET_SAVE_FORM_SUBMISSION_RESPONSE_NETWORK_ERROR"

export const RESER_ALL_STATE="RESER_ALL_STATE"

//SENT MAIL TO PARTNER
export const SEND_EMAIL_TO_PARTNER_INITIAL ="SEND_EMAIL_TO_PARTNER_INITIAL"
export const SEND_EMAIL_TO_PARTNER_SUCCESS ="SEND_EMAIL_TO_PARTNER_SUCCESS"
export const SEND_EMAIL_TO_PARTNER_FAIL ="SEND_EMAIL_TO_PARTNER_FAIL"
export const SEND_EMAIL_TO_PARTNER_NETWORK_ERROR="SEND_EMAIL_TO_PARTNER_NETWORK_ERROR"

//Delete EMAIL of PARTNER
export const DELETE_EMAIL_OF_PARTNER_INITIAL ="DELETE_EMAIL_OF_PARTNER_INITIAL"
export const DELETE_EMAIL_OF_PARTNER_SUCCESS ="DELETE_EMAIL_OF_PARTNER_SUCCESS"
export const DELETE_EMAIL_OF_PARTNER_FAIL ="DELETE_EMAIL_OF_PARTNER_FAIL"
export const DELETE_EMAIL_OF_PARTNER_NETWORK_ERROR="DELETE_EMAIL_OF_PARTNER_NETWORK_ERROR"

