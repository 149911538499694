import React from 'react';
import {Row,Col, Form, Button,Modal} from 'react-bootstrap';

const Modaldiv = (props) => {
    return (
        <Modal
        show={props.show}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
       <span style={{textAlign:"center"}}>Link Copy to Clipboard</span>
      </Modal>
   
    );
}

export default Modaldiv;