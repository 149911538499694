import React, { PureComponent } from 'react';
import {Row,Col,Button,Table} from 'react-bootstrap';
import classes from './createform.module.css'
import {NavLink, Link, Redirect, withRouter } from 'react-router-dom';
import {SimpleButton,HoverButton} from '../Buttons/Buttons'
import Pagination from '../Pagination/Pagination';
import { connect } from 'react-redux';
import * as actionTypes from '../../Action/Index';
class Createform extends PureComponent{
  state={
    currentPage:1
  }

  onPageChange = (id) => {
    this.setState({
       currentPage:1,
   })
 
}
onLeftChange = () => {
   if (this.state.currentPage==1) {
       return
   }
   this.setState({
       currentPage: this.state.currentPage -1,
   },()=>{
    //this.props.getPartnerList(this.state.currentPage);
   })
  
}
onRightChange = () => {
  
   if (5 == this.state.currentPage) {
       return
   }
   this.setState({
       currentPage: this.state.currentPage +1,
   },()=>{
   // this.props.getPartnerList(this.state.currentPage);
   })
   
}

componentDidMount=()=>{
  this.props.getFormsList(1);
}

    render(){
        return(
            <div>
            <Row>
            <Col md={1}></Col>
            <Col md={10} style={{display:"flex",justifyContent:"space-between"}}>
                <div className={classes.heading}>
                <h3>Form</h3>
                </div>

                <div className={classes.heading}>
                <h4 onClick={()=>this.props.history.push('/createform/editform',{type:"addnew",fieldValue:""})}><i class="fas fa-plus" ></i>&nbsp;Add Form</h4>
                </div>
            </Col>
        </Row>

        <Row>
        <Col md={2}></Col>
        <Col md={8} className="table-responsive">
        <table className={classes.scheduleTbl}>
        <thead>
          <tr>
            <th>Sr.</th>
            <th>Forms</th>
            <th style={{textAlign:"center"}}>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Form type 1</td>
            <td style={{textAlign:"center"}}>
            <SimpleButton width="80px" fun={()=>this.props.history.push('/createform/formtype')}>
            Edit
           </SimpleButton>
            </td>
            
          </tr>
          <tr>
            <td>2</td>
            <td>Form type 2</td>
            <td style={{textAlign:"center"}}>
            <SimpleButton width="80px" fun={()=>this.props.history.push('/createform/formtype')}>
              Edit
           </SimpleButton>
            </td>
            
          </tr>
          <tr>
            <td>3</td>
            <td>Form type 3</td>
            <td style={{textAlign:"center"}}>
            <SimpleButton width="80px" fun={()=>this.props.history.push('/createform/formtype')}>
            Edit
         </SimpleButton>
            </td>
            
          </tr>

          <tr>
            <td>4</td>
            <td>Form type 4</td>
            <td style={{textAlign:"center"}}>
            <SimpleButton width="80px" fun={()=>this.props.history.push('/createform/formtype')}>
            Edit
         </SimpleButton>
            </td>
            
          </tr>

          <tr>
            <td>5</td>
            <td>Form type 5</td>
            <td style={{textAlign:"center"}}>
            <SimpleButton width="80px" fun={()=>this.props.history.push('/createform/formtype')}>
            Edit
         </SimpleButton>
            </td>
            
          </tr>
        </tbody>
      </table>
        </Col>
        <Col md={2}></Col>
    </Row>
      
    <div className={classes.footer}>
    <Row>

                                        <Col md={12}>
                                            {true ?
                                                <Pagination
                                                    activePage={this.state.currentPage}
                                                    pageRangeDisplayed={5}
                                                    onPageChange={this.onPageChange}
                                                    onLeftChange={this.onLeftChange}
                                                    onRightChange={this.onRightChange}
                                                />
                                                : null}
                                        </Col>

                                        
                                    </Row>
    </div>
</div>
        )
    }
    
}


const mapStateToProps = state => {
  return {
      getFormsLoader:state.getFormsLoader,
      getFormsList:state.getFormsList,
      getFormsTotalpages:state.getFormsTotalpages,
      getFormsError:state.getFormsError,
      getFormsNetissue:state.getFormsNetissue
  }
}

const mapDispatchToProps = dispatch => {
  return {
      getFormsList: (page) => dispatch(actionTypes.getFormsList(page)),
       }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Createform));