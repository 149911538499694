import React, { PureComponent } from 'react';
import {Row,Col,Card,Container} from 'react-bootstrap';
import logo from '../../Assets/Logo_InnovationM/1x/InnovationM Logomdpi.png'
import classes from './usersubmissionsucess.module.css';
class UserSubmissionSuccess extends PureComponent {

    render(){

        return(
            <Container>
  <Row>
  <Col lg={3} md={2} sm={1}></Col>
    <Col lg={6} md={8} sm={10}>
    <Card style={{marginTop:"30px"}}>
    <Card.Header className={classes.cardHeader}>
    <img src={logo} style={{width:"200px",height:"50px"}}/>
    </Card.Header>
  <Card.Body>
  
  {this.props.location.state!=undefined?<div>
  <p>{this.props.location.state.responseMsg}</p>
  {/*this.props.location.state.url!==null?
  <a href={this.props.location.state.url}>Submit another response</a>
  :null */}
   </div>:"Some thing went wrong, please try again."}
  </Card.Body>
</Card>
    </Col>
    <Col lg={3} md={2} sm={1}></Col>
  </Row>
</Container>
        );
    }
}


export default UserSubmissionSuccess;