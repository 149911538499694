import React, { PureComponent } from 'react';
import {Row,Col,Button} from 'react-bootstrap';
import classes from './submission.module.css'
import {SimpleButton,HoverButton,DisabledButton} from '../Buttons/Buttons'
import Pagination from '../Pagination/Pagination';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from 'react-redux';
import * as actionTypes from '../../Action/Index';
import Loader from 'react-loader-advanced';
import loading from '../../Assets/Loader/loadert3.gif';
import ViewResponse from './ViewResponse';
class Submission extends PureComponent{
  state={
    listArray:null,
    currentPage:1,
    modelShow:false,
    partnerSubmissionResponseModel:null,
    startDate:null,
    endDate:null,
    additionalMessage:null
  }
  toggleModel=(data)=>{
    this.setState({modelShow:!this.state.modelShow,partnerSubmissionResponseModel:data})
}
  onPageChange = (id) => {
    this.setState({
       currentPage:1,
   })
 
}
onLeftChange = () => {
   if (this.state.currentPage==1) {
       return
   }
   this.setState({
       currentPage: this.state.currentPage -1,
   },()=>{
     this.props.getSubmissionDetailsListMethod(this.state.currentPage,this.state.startDate,this.state.endDate);
   })
  
}
onRightChange = () => {
  
   if (this.state.currentPage==this.props.getSubmissionDetailsTotalpages) {
       return
   }
   this.setState({
       currentPage: this.state.currentPage +1,
   },()=>{
     this.props.getSubmissionDetailsListMethod(this.state.currentPage,this.state.startDate,this.state.endDate);
   })
   
}
setStartDateFrom=(date)=>{
  this.setState({
    startDate:date,
    currentPage:1
  },()=>{
    if(this.state.startDate!==null && this.state.endDate!=null){
      this.props.getSubmissionDetailsListMethod(this.state.currentPage,this.state.startDate,this.state.endDate);
    }
  })
}
setStartDateTo=(date)=>{
  this.setState({
    endDate:date,
    currentPage:1
  },()=>{
    if(this.state.startDate!==null && this.state.endDate!=null){
      this.props.getSubmissionDetailsListMethod(this.state.currentPage,this.state.startDate,this.state.endDate);
    }
  })
}
clearFilter=()=>{
  if(this.state.endDate!=null || this.state.startDate!=null){
    this.setState({
      endDate:null,
      startDate:null,
      currentPage:1
    },()=>{
        this.props.getSubmissionDetailsListMethod(this.state.currentPage,this.state.startDate,this.state.endDate);
    })
  }
}

componentDidMount=()=>{
  this.props.getSubmissionDetailsListMethod(this.state.currentPage,this.state.startDate,this.state.endDate);
}


componentWillReceiveProps=(nextProps)=>{
  if(nextProps.getSubmissionDetailsList!=null && nextProps.getSubmissionDetailsList.length!==0){
      this.setState({
        listArray: nextProps.getSubmissionDetailsList,
          
      })
  }else if(nextProps.getSubmissionDetailsList==null){
    this.setState({
      listArray: nextProps.getSubmissionDetailsList,
        
    })
  }
}



    render(){

let list=null;
 if(this.state.listArray!=null){
    list=this.state.listArray.map(ind=>{
      return(
        <tr>
            <td>{ind.partnerEmail}</td>
            <td style={{textAlign:"center"}}>{ind.submissionStatus?"Y":"N"}</td>
            {ind.submissionStatus?<td><SimpleButton width="70px" fun={()=>this.toggleModel(ind.partnerSubmissionResponseModel)}>
            View
            </SimpleButton></td>:<td><DisabledButton width="70px">
            View
            </DisabledButton></td>}
            
          </tr>
      )
    })
  }else{
    list=<tr><td colSpan="3" style={{textAlign:"center"}}>List of Submission details will Show up here.</td></tr>
  }

    const ExampleCustomInput = ({ value, onClick }) => (
      <button className={classes.calenderButton} style={{outline:"none"}} onClick={onClick}>
        {value}
      </button>
    );
   
        return(
            <div>
            <Row>
            <Col md={1}></Col>
            <Col md={10} style={{display:"flex",justifyContent:"space-between"}}>
                <div className={classes.heading}>
                <h3>Submissions</h3>
                </div>

                <div className={classes.calender}>
                From &nbsp;&nbsp;
                <span>
                <DatePicker
                 selected={this.state.startDate}
                 dateFormat="dd/MM/yyyy"
                 maxDate={this.state.endDate==null?new Date():this.state.endDate}
                 customInput={<ExampleCustomInput />}
                  onChange={date => this.setStartDateFrom(date)} />
                </span>&nbsp;&nbsp;
                To &nbsp;&nbsp;
                <span>
                <DatePicker
                 selected={this.state.endDate}
                 dateFormat="dd/MM/yyyy"
                 minDate={this.state.startDate}
                  maxDate={new Date()}
                 customInput={<ExampleCustomInput />}
                  onChange={date => this.setStartDateTo(date)} />
                </span> &nbsp;&nbsp;
                <span>
                <SimpleButton width="70px" color="#fbb03b" padding="2px 0px" fun={()=>this.clearFilter()}>
                 Clear
                </SimpleButton>
                </span>
                </div>
                
                
            </Col>
        </Row>

        <Loader show={this.props.getSubmissionDetailsLoader} message={<img width="150px" src={loading} />}
        foregroundStyle={{ color: '#00a99d' }}
        backgroundStyle={{ backgroundColor: 'white' }}
        >
        <Row>
        <Col md={2}></Col>
        <Col md={8} className="table-responsive">
        <table className={classes.scheduleTbl}>
        <thead>
          <tr>
            <th>Partners</th>
            <th style={{textAlign:"center"}}>Submission Status</th>
            <th>Response</th>
          </tr>
        </thead>
        <tbody>
        {list}
        </tbody>
      </table>
        </Col>
        <Col md={2}></Col>
    </Row>

    <div className={classes.footer}>
    <Row>

                                        <Col md={12}>
                                            {this.props.getSubmissionDetailsTotalpages>1?
                                                <Pagination
                                                    activePage={this.state.currentPage}
                                                    pageRangeDisplayed={this.props.getSubmissionDetailsTotalpages}
                                                    onPageChange={this.onPageChange}
                                                    onLeftChange={this.onLeftChange}
                                                    onRightChange={this.onRightChange}
                                                />
                                                : null}
                                        </Col>

                                        
                                    </Row>
    </div>
    </Loader>
    <ViewResponse 
    show={this.state.modelShow}
    partnerSubmissionResponseModel={this.state.partnerSubmissionResponseModel}
    toggleModel={this.toggleModel}/>
</div>
        )
    }
    
}

const mapStateToProps = state => {
  return {
      getSubmissionDetailsLoader:state.getSubmissionDetailsLoader,
      getSubmissionDetailsList:state.getSubmissionDetailsList,
      getSubmissionDetailsTotalpages:state.getSubmissionDetailsTotalpages,
      getSubmissionDetailsError:state.getSubmissionDetailsError,
      getSubmissionDetailsNetissue:state.getSubmissionDetailsNetissue
  }
}

const mapDispatchToProps = dispatch => {
  return {
      getSubmissionDetailsListMethod: (page,start,end) => dispatch(actionTypes.getSubmissionDetailsListMethod(page,start,end)),
       }
}
export default connect(mapStateToProps, mapDispatchToProps)(Submission);
