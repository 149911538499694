import * as actionTypes from '../Action/ActionTypes';

const initialState = {
    loginErrorMsg: "",
    loader: false,
    loginStatus: false,

    getpartnerLoader:false,
    getpartnerList:[],
    getpartnerTotalpages:0,
    getpartnerError:"",
    getpartnerNetissue:false,

    addpartnerLoader:false,
    addpartnerList:[],
    addpartnerTotalpages:0,
    addpartnerError:"",
    addpartnerNetissue:false,

    getFormsLoader:false,
    getFormsList:[],
    getFormsError:"",
    getFormsNetissue:false,
    getFormsTotalpages:0,
    partnerName:null,
    getSaveError:"",
    saveFormStatus:false,
    sendMailLoader:false,
    sendMailStatus:false,

    getSubmissionDetailsLoader:false,
      getSubmissionDetailsList:null,
      getSubmissionDetailsTotalpages:1,
      getSubmissionDetailsError:"",
      getSubmissionDetailsNetissue:""

}

export const rootReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.LOGIN_INITIAL:
            return {
                ...state,
                loginErrorMsg: "",
                loader: true,
                loginStatus: false,
            }
        case actionTypes.LOGIN_SUCCESS:
            return {
                ...state,
                loginStatus: action.payload,
                loginErrorMsg: "",
                loader: false,
            }
        case actionTypes.LOGIN_FAIL:
            return {
                ...state,
                loginStatus: false,
                loginErrorMsg: action.payload,
                loader: false,
            }
            
            case actionTypes.GET_PARTNER_LIST_INITIAL:
                return {
                    ...state,
                    getpartnerLoader:true,
                    getpartnerList:[],
                    getpartnerError:"",
                    getpartnerNetissue:false,
                    getpartnerTotalpages:0
                }
            case actionTypes.GET_PARTNER_LIST_SUCCESS:
                return {
                    ...state,
                    getpartnerLoader:false,
                    getpartnerList:action.payload,
                    getpartnerTotalpages:action.totalPages,
                    getpartnerError:"",
                    getpartnerNetissue:false
                }
            case actionTypes.GET_PARTNER_LIST_FAIL:
                return {
                    ...state,
                    getpartnerLoader:false,
                    getpartnerList:[],
                    getpartnerTotalpages:0,
                    getpartnerError:action.errorMsg,
                    getpartnerNetissue:false
                }
                case actionTypes.GET_PARTNER_LIST_NETWORK_ERROR:
                return {
                    ...state,
                    getpartnerLoader:false,
                    getpartnerList:[],
                    getpartnerTotalpages:0,
                    getpartnerError:action.errorMsg,
                    getpartnerNetissue:true
                }


                case actionTypes.ADD_PARTNER_LIST_INITIAL:
                return {
                    ...state,
                    addpartnerLoader:true,
                    addpartnerList:[],
                    addpartnerError:"",
                    addpartnerNetissue:false,
                    addpartnerTotalpages:0
                }
            case actionTypes.ADD_PARTNER_LIST_SUCCESS:
                return {
                    ...state,
                    addpartnerLoader:false,
                    addpartnerList:action.payload,
                    addpartnerTotalpages:action.totalPages,
                    addpartnerError:"",
                    addpartnerNetissue:false
                }
            case actionTypes.ADD_PARTNER_LIST_FAIL:
                return {
                    ...state,
                    addpartnerLoader:false,
                    addpartnerList:[],
                    addpartnerTotalpages:0,
                    addpartnerError:action.errorMsg,
                    addpartnerNetissue:false
                }
                case actionTypes.ADD_PARTNER_LIST_NETWORK_ERROR:
                return {
                    ...state,
                    addpartnerLoader:false,
                    addpartnerList:[],
                    addpartnerTotalpages:0,
                    addpartnerError:action.errorMsg,
                    addpartnerNetissue:true
                }


                case actionTypes.GET_FORM_LIST_INITIAL:
                    return {
                        ...state,
                        getFormsLoader:true,
                        getFormsList:[],
                        getFormsError:"",
                        getFormsNetissue:false,
                        getFormsTotalpages:0
                    }
                case actionTypes.GET_FORM_LIST_SUCCESS:
                    return {
                        ...state,
                        getFormsLoader:false,
                        getFormsList:action.payload,
                        getFormsTotalpages:action.totalPages,
                        getFormsError:"",
                        getFormsNetissue:false
                    }
                case actionTypes.GET_FORM_LIST_FAIL:
                    return {
                        ...state,
                        getFormsLoader:false,
                        getFormsList:[],
                        getFormsTotalpages:0,
                        getFormsError:action.errorMsg,
                        getFormsNetissue:false
                    }
                    case actionTypes.GET_FORM_LIST_NETWORK_ERROR:
                    return {
                        ...state,
                        getFormsLoader:false,
                        getFormsList:[],
                        getFormsTotalpages:0,
                        getFormsError:action.errorMsg,
                        getFormsNetissue:true
                    }

                    case actionTypes.GET_FORM_SUBMISSION_DETAILS_INITIAL:
                        return {
                            ...state,
                            getFormsSubmissionDetailsLoader:true,
                            getFormsSubmissionDetailsList:[],
                            getFormsSubmissionDetailsError:"",
                            getFormsSubmissionDetailsNetissue:false,
                            partnerName:null,
                            submissionId:null
                                                   }
                    case actionTypes.GET_FORM_SUBMISSION_DETAILS_SUCCESS:
                        return {
                            ...state,
                            getFormsSubmissionDetailsLoader:false,
                            getFormsSubmissionDetailsList:action.payload,
                            getFormsSubmissionDetailsError:"",
                            getFormsSubmissionDetailsNetissue:false,
                            partnerName:action.partnerName,
                            submissionId:action.submissionId
                        }
                    case actionTypes.GET_FORM_SUBMISSION_DETAILS_FAIL:
                        return {
                            ...state,
                            getFormsSubmissionDetailsLoader:false,
                            getFormsSubmissionDetailsList:[],
                            getFormsSubmissionDetailsError:action.errorMsg,
                            getFormsSubmissionDetailsNetissue:true,
                            partnerName:null,
                            submissionId:null
                        }
                        case actionTypes.GET_FORM_SUBMISSION_DETAILS_NETWORK_ERROR:
                        return {
                            ...state,
                            getFormsSubmissionDetailsLoader:false,
                            getFormsSubmissionDetailsList:[],
                            getFormsSubmissionDetailsError:action.errorMsg,
                            getFormsSubmissionDetailsNetissue:true,
                            partnerName:null,
                            submissionId:null
                        }
    
                        case actionTypes.GET_SAVE_FORM_SUBMISSION_RESPONSE_INITIAL:
                            return {
                                ...state,
                                getFormsLoader:true,
                                getSaveError:"",
                                saveFormStatus:false,
                                getFormsSubmissionDetailsNetissue:false,
                            }
                        case actionTypes.GET_SAVE_FORM_SUBMISSION_RESPONSE_SUCCESS:
                            return {
                                ...state,
                                getFormsLoader:true,
                                getSaveError:"",
                                saveFormStatus:true,
                                getFormsSubmissionDetailsNetissue:false,
                            }
                        case actionTypes.GET_SAVE_FORM_SUBMISSION_RESPONSE_FAIL:
                            return {
                                ...state,
                                getFormsLoader:true,
                                getSaveError:"Some thing went wrong, please try again.",
                                getFormsSubmissionDetailsNetissue:true,
                            }
                            case actionTypes.GET_SAVE_FORM_SUBMISSION_RESPONSE_NETWORK_ERROR:
                            return {
                                ...state,
                                getFormsLoader:true,
                                getSaveError:"Some thing went wrong, please try again.",
                                getFormsSubmissionDetailsNetissue:true,
                            }
                            case actionTypes.RESER_ALL_STATE:
                                return {
                                    ...state,
                                    getFormsSubmissionDetailsLoader:false,
                                    getFormsSubmissionDetailsList:[],
                                    getFormsSubmissionDetailsError:"",
                                    getFormsSubmissionDetailsNetissue:false,
                                    partnerName:null,
                                    submissionId:null,
                                    saveFormStatus:false,
                                }

                                case actionTypes.SEND_EMAIL_TO_PARTNER_INITIAL:
                                    return {
                                        ...state,
                                       sendMailLoader:true,
                                       sendMailStatus:false
                                    }
                                case actionTypes.SEND_EMAIL_TO_PARTNER_SUCCESS:
                                    return {
                                        ...state,
                                        sendMailLoader:false,
                                       sendMailStatus:true
                                    }
                                case actionTypes.SEND_EMAIL_TO_PARTNER_FAIL:
                                    return {
                                        ...state,
                                        sendMailLoader:false,
                                        sendMailStatus:false
                                    }
                                    case actionTypes.SEND_EMAIL_TO_PARTNER_NETWORK_ERROR:
                                    return {
                                        ...state,
                                        sendMailLoader:false,
                                        sendMailStatus:false
                                    }

                                    case actionTypes.GET_SUBMISSION_DETAILS_INITIAL:
                                        return {
                                            ...state,
                                            getSubmissionDetailsLoader:true,
                                            getSubmissionDetailsList:null,
                                            getSubmissionDetailsTotalpages:0,
                                            getSubmissionDetailsError:"",
                                            getSubmissionDetailsNetissue:""
                                        }
                                    case actionTypes.GET_SUBMISSION_DETAILS_SUCCESS:
                                        return {
                                            ...state,
                                            getSubmissionDetailsLoader:false,
                                            getSubmissionDetailsList:action.payload,
                                            getSubmissionDetailsTotalpages:action.totalPages,
                                            getSubmissionDetailsError:"",
                                            getSubmissionDetailsNetissue:""
                                        }
                                    case actionTypes.GET_SUBMISSION_DETAILS_FAIL:
                                        return {
                                            ...state,
                                            getSubmissionDetailsLoader:false,
                                            getSubmissionDetailsList:null,
                                            getSubmissionDetailsTotalpages:0,
                                            getSubmissionDetailsError:"",
                                            getSubmissionDetailsNetissue:""
                                        }
                                        case actionTypes.GET_SUBMISSION_DETAILS_NETWORK_ERROR:
                                        return {
                                            ...state,
                                            getSubmissionDetailsLoader:false,
                                            getSubmissionDetailsList:null,
                                            getSubmissionDetailsTotalpages:0,
                                            getSubmissionDetailsError:"",
                                            getSubmissionDetailsNetissue:""
                                        }
            default: return state
        }
    }