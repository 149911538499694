import React, { PureComponent } from 'react';
import {Row,Col,Button, Form} from 'react-bootstrap';
import classes from './editform.module.css'
import {SimpleButton,HoverButton} from '../Buttons/Buttons'
class Editform extends PureComponent
 {
     render(){
       console.log(this.props.location.state.type)
        return (
            <div>
            <Row>
            <Col md={1}></Col>
            <Col md={10} style={{display:"flex",justifyContent:"space-between"}}>
                <div className={classes.heading}>
                {
                  this.props.location.state.type=="addnew"? <h3>Add New Form</h3>: <h3>Edit Field</h3>
                }
               
                </div>
            </Col>
        </Row>

        <Row>
            <Col md={1}></Col>
            <Col md={10} style={{paddingLeft: "25px"}} >
            <Form>
            <Form.Group controlId="exampleForm.ControlTextarea1" style={{marginTop: "15px"}}>
            <Form.Label className={classes.responseType}>Heading*</Form.Label>
            <Form.Control as="textarea" rows="3" value={this.props.location.state.fieldValue}/>
            </Form.Group>
             <div className={classes.formlabel} style={{marginTop: "30px"}}>
             <div className={classes.responseType}>Response Type *</div>
            <div>
          <input type="radio" name="gridRadios" id="gridRadios1" value="option1"/>
          <label>
            Checkbox
          </label>
        </div>
        <div>
          <input type="radio" name="gridRadios" id="gridRadios2" value="option2" />
          <label>
            Drop Down
          </label>
        </div>
 
        <div>
          <input type="radio" name="gridRadios" id="gridRadios1" value="option3"/>
          <label>
            Radio Button
          </label>
        </div>

        <div>
          <input type="radio" name="gridRadios" id="gridRadios1" value="option4"/>
          <label>
            Text Box
          </label>
        </div>
        </div>

        <Form.Group controlId="exampleForm.ControlTextarea2" style={{marginTop: "15px"}}>
            <Form.Label className={classes.responseType}>Enter multiple values by seperating with commas.</Form.Label>
            <Form.Control as="textarea" rows="3" />
            </Form.Group>
            {
              this.props.location.state.type=="addnew"?
              <div style={{display:"flex",justifyContent:"space-around"}}>
              <SimpleButton width="130px" fun={()=>alert("hsagdh")}>
              Add Field
              </SimpleButton>

              <SimpleButton width="130px" fun={()=>alert("hsagdh")}>
              Create Form
              </SimpleButton>
              </div>
              :  <div className={classes.makeincenter}>
              <SimpleButton width="130px" fun={()=>alert("hsagdh")}>
              Update Field
              </SimpleButton>
              </div>
            }
           
            </Form>
            </Col>
        </Row>
        </div>
        )
     }

}

export default Editform;