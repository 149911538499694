import React, { PureComponent } from 'react';
import {Route,withRouter, Switch, Redirect} from 'react-router-dom';
import UsersubmissionForm from './component/UserSubmissionform/UsersubmissionForm';
import axios from 'axios';
import UserSubmissionSuccess from './component/UserSubmissionSuccess/UserSubmissionSuccess';

import Login from './component/Login/login'
import Container from './container/container';
import Addpartner from './component/Addpartner/Addpartner';
import Submission from './component/SubmissionDetails/Submission';
import Createform from './component/CreateForm/Createform';
import Formtype from './component/FormType/Formtype';
import Editform from './component/EditForm/Editform';
import ModeofInterview from './component/Addpartner/Modeofinterview'
import {SimpleButton,HoverButton} from './component/Buttons/Buttons'
axios.interceptors.request.use(req => {
  console.log("request...", req);
  return req;
})

axios.interceptors.response.use(res => {
   console.log("response...", res);
  return res;
})

class App extends PureComponent {
    constructor(props){
      super(props);
      this.state={
        loginToggle:false
      }
    }

render(){

  let container = null;
  if(localStorage.getItem('JitToken')!=null && localStorage.getItem('JitToken')!="" && localStorage.getItem('JitToken')!=undefined)
  {
     container = ( 
      <Container>
      <Switch>
      <Route path ="/addpartner" exact strict component = {Addpartner}/>
      <Route path="/submissiondetails" exact strict component={Submission}/>
      <Route path="/createform" exact strict component={Createform}/>
      <Route path="/createform/formtype" exact strict component={Formtype}/>
      <Route path="/createform/editform" exact strict component={Editform}/>
      <Redirect to="/addpartner"/>
      </Switch>
     </Container> 
     );
  }
  else{
    container = (<div>
      <Switch>
      <Route path="/login" exact strict component={Login}/>
       <Route path="/usersubmissionform" exact strict component={UsersubmissionForm}/>
       <Route path="/usersubmissionsuccess" exact strict component={UserSubmissionSuccess}/>
       <Redirect to="/usersubmissionform"/>
       </Switch>
      </div>
    );
  }
  return (
    <div>
    {container}
    </div>
  )
}

}

export default withRouter(App);
