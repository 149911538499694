import React, {Component} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import classes from './ConfirmSweetAlert.module.css';


class ConfirmAlert extends Component {
    
        render(){
        
        return(
         this.props.show ?<SweetAlert
          warning
          showCancel
          confirmBtnText="Confirm"
          confirmBtnCssClass = {classes.confirmButton}
          cancelBtnCssClass = {classes.cancelButton}
          cancelBtnBsStyle = "none"
          confirmBtnBsStyle = "none"
        //   confirmBtnStyle = {{background: '#fbb03b', borderRadius: '50px', width: '100px', border: 'none', padding: '0.250rem 0.75rem',marginLeft:"10px"}}
        //  cancelBtnStyle={{background: 'white', borderRadius: '50px', width: '100px', border: '1px solid orange', padding: '0.250rem 0.75rem', color: '#fbb03b', textDecoration: 'none',marginRight:"10px"}}
          onConfirm={this.props.nextAction}
          onCancel={() => {this.props.cancelHandler()}}
      >
          <span>{this.props.msg}</span>
           <p>{this.props.msg2}</p>  
          
      </SweetAlert> : null
        );
    }
}

export default ConfirmAlert;