import React, { Component } from 'react'
import {Row,Col, FormControl, Button,Modal,Form} from 'react-bootstrap';
import classes from './modeofinterview.module.css'
import {connect} from 'react-redux';
import * as actionTypes from '../../Action/Index';
export class Modeofinterview extends Component {
    state={
      checkedvalue:null,
      errorMsg:null,
      emailErrorMsg:null,
      nameErrorMsg:null,
      locationErrorMsg:null,
      typeErrorMsg:null
    }
 
closeAndReset=()=>{
  this.state.emailErrorMsg=null;
  this.state.nameErrorMsg=null;
  this.state.agencyErrorMsg=null;
  this.state.locationErrorMsg=null;
  this.state.typeErrorMsg=null
  this.props.toggleModel();

}
addPartner=()=>{
 
  if (this.name.value === "") {
    this.setState({
        nameErrorMsg:"Enter your Name",
       
    })
  }
 else if (this.Agencyname.value === "") {
    this.setState({
        agencyErrorMsg:"Enter Agency Name",
       
    })
  }
  else if (this.location.value === "") {
    this.setState({
         locationErrorMsg:"Enter Agency Location",
       
    })
  }
  else if (document.getElementById("type").value === "Select Company Type") {
    this.setState({
         typeErrorMsg:"Select Company Type",
       
    })
  }
else if (this.pemail.value === "") {
  this.setState({
      emailErrorMsg:"Enter your Email ID",
     
  })
}
else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.pemail.value.trim())) {
  this.setState({
      emailErrorMsg:"Please enter a valid Email ID",
     
  })
}
else{
  this.props.addPartner(this.pemail.value.trim(),this.name.value.trim(),this.Agencyname.value.trim(),this.location.value.trim(),document.getElementById("type").value);
  this.props.toggleModel();
  this.props.onPageChange();
}
}
    render() {

        return (
          <>
          <Modal centered show={this.props.show} backdrop= "false" >
          <h4 className={classes.headingClass}>
          Add It - Partner
          </h4>

          <Row>   
          <Col lg={12}>
          <FormControl
          placeholder="Enter Name"
          className={classes.inputClass}
          ref={element => this.name = element}
          onChange={()=>this.setState({nameErrorMsg:null})}
          maxLength="30"
        />
        </Col>
        </Row>
        <Row>
        <Col lg={12} className={classes.errorMsg}>
          {this.state.nameErrorMsg!==null?this.state.nameErrorMsg:null}
          </Col>
        </Row>

          <Row>   
          <Col lg={12}>
          <FormControl
          placeholder="Enter Agency Name"
          className={classes.inputClass}
          ref={element => this.Agencyname = element}
          onChange={()=>this.setState({agencyErrorMsg:null})}
          maxLength="35"
        />
        </Col>
        </Row>
        <Row>
        <Col lg={12} className={classes.errorMsg}>
          {this.state.agencyErrorMsg!==null?this.state.agencyErrorMsg:null}
          </Col>
        </Row>

        <Row>   
        <Col lg={12}>
        <FormControl
        placeholder="Enter Location"
        className={classes.inputClass}
        ref={element => this.location = element}
        onChange={()=>this.setState({locationErrorMsg:null})}
        maxLength="35"
      />
      </Col>
      </Row>
      <Row>
      <Col lg={12} className={classes.errorMsg}>
        {this.state.locationErrorMsg!==null?this.state.locationErrorMsg:null}
        </Col>
      </Row>

      <Row>   
      <Col lg={12}>
      <Form.Control as="select" id="type" name="type"
      onChange={()=>this.setState({typeErrorMsg:null})}
      className={classes.inputClass}>
      <option>Select Company Type</option>
      <option value="IT_Company" id="IT_Company">IT Company</option>
      <option value="IT_Staffing" id="IT_Staffing">IT Staffing</option>
    </Form.Control>
    </Col>
    </Row>
    <Row>
    <Col lg={12} className={classes.errorMsg}>
      {this.state.typeErrorMsg!==null?this.state.typeErrorMsg:null}
      </Col>
    </Row>

        <Row>
             <Col lg={12}>
             <FormControl
             placeholder="Enter Email Id"
             className={classes.inputClass}
             ref={element => this.pemail = element}
             onChange={()=>this.setState({emailErrorMsg:null})}
             maxLength="42"
           />
           </Col>
           </Row>

           <Row>
             <Col lg={12} className={classes.errorMsg}>
               {this.state.emailErrorMsg!==null?this.state.emailErrorMsg:null}
               </Col>
             </Row>
 
        <div className={classes.buttonDiv}>
         
            <button className={classes.closeSubmit} onClick={()=>this.closeAndReset()} style={{marginRight:"10px"}}>Close</button>
              <button className={classes.closeSubmit} style={{marginLeft:"10px"}} onClick={()=>this.addPartner()}>Submit</button>
       
        </div>
      </Modal>
          </>
        )
    }
}
const mapStateToProps = state => {
  return {
    addpartnerLoader:state.addpartnerLoader,
    addpartnerList:state.addpartnerList,
    addpartnerTotalpages:state.addpartnerTotalpages,
    addpartnerError:state.addpartnerError,
    addpartnerNetissue:state.addpartnerNetissue
      
  }
}
const mapDispatchToProps = dispatch => {
  
  return {
      addPartner: (email,name,agency,location,type) => dispatch(actionTypes.addPartner(email,name,agency,location,type)),
       }
}
export default connect(mapStateToProps,mapDispatchToProps)(Modeofinterview);
