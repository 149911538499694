import React, { Component } from 'react'
import {Row,Col, FormControl, Button,Modal,Form} from 'react-bootstrap';
import classes from './editpartner.module.css'
import {connect} from 'react-redux';
import * as actionTypes from '../../Action/Index';
export class Editpartner extends Component {
    state={
      checkedvalue:null,
      errorMsg:null,
      emailErrorMsg:null,
      nameErrorMsg:null,
      agencyName:"",
      partnerName:"",
      location:"",
      email:""
    }
 
closeAndReset=()=>{
  this.state.emailErrorMsg=null;
  this.state.nameErrorMsg=null;
  this.state.agencyErrorMsg=null;
  this.state.locationErrorMsg=null;
  this.state.typeErrorMsg=null
  this.props.toggleModel();

}
addPartner=(partnerId)=>{
 
  if (this.name.value === "") {
    this.setState({
        nameErrorMsg:"Enter your Name",
       
    })
  }
 else if (this.agencyName.value === "") {
    this.setState({
        agencyErrorMsg:"Enter Agency Name",
       
    })
  }
  else if (this.location.value === "") {
    this.setState({
         locationErrorMsg:"Enter Agency Location",
       
    })
  }
  else if (document.getElementById("type").value === "Select Company Type") {
    this.setState({
         typeErrorMsg:"Select Company Type",
       
    })
  }
else if (this.email.value === "") {
  this.setState({
      emailErrorMsg:"Enter your Email ID",
  })
}
else if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email.value.trim())) {
  this.setState({
      emailErrorMsg:"Please enter a valid Email ID",
     
  })
}
else{
  this.props.editPartner(this.email.value.trim(),this.name.value.trim(),this.agencyName.value.trim(),this.location.value.trim(),document.getElementById("type").value,partnerId);
  this.props.toggleModel();
  this.props.onPageChange()
}
}

componentDidMount=()=>{
    if(this.props.emailEdit.type==null){
      document.getElementById("none").selected=true
    }
    else{
      document.getElementById(this.props.emailEdit.type).selected=true
    }
   
}

    render() {

    if(this.props.emailEdit!=null){
       this.name=this.props.emailEdit.name
      this.agencyName=this.props.emailEdit.companyName
      this.location=this.props.emailEdit.location
      this.email=this.props.emailEdit.email
     
        return (
          <div>
          <Modal centered show={this.props.show} backdrop= "false" >
          <h4 className={classes.headingClass}>
          Edit It - Partner
          </h4>

          <Row>   
          <Col lg={12} style={{padding: "0px 25px"}}>
          <Form.Group as={Row} style={{margin: "0px"}}>
          <Form.Label column sm={2}>
            Name:
          </Form.Label>
          <Col sm={10}>
            <Form.Control
             type="text"
              placeholder="Enter Partner Name" 
              name="partnerName"
              defaultValue={this.props.emailEdit.name}
              ref={element => this.name = element}
              onChange={()=>this.setState({nameErrorMsg:null})}
             maxLength="30"
              />
          </Col>
        </Form.Group>
        </Col>
        </Row>
        <Row>
        <Col lg={2}></Col>
        <Col lg={10}  className={classes.errorMsg}>
          {this.state.nameErrorMsg!==null?this.state.nameErrorMsg:null}
          </Col>
        </Row>

          <Row>   
          <Col lg={12} style={{padding: "0px 25px"}}>
          <Form.Group as={Row} style={{margin: "0px"}}>
          <Form.Label column sm={2}>
           Agency:
          </Form.Label>
          <Col sm={10}>
            <Form.Control 
            type="text" 
            placeholder="Enter Agency Name"
            name="agencyName"
            defaultValue={this.props.emailEdit.companyName}
            ref={element => this.agencyName = element}
            onChange={()=>this.setState({agencyErrorMsg:null})}
            maxLength="35"
             />
          </Col>
        </Form.Group>
        </Col>
        </Row>
        <Row>
        <Col lg={12} className={classes.errorMsg}>
          {this.state.agencyErrorMsg!==null?this.state.agencyErrorMsg:null}
          </Col>
        </Row>

        <Row>   
        <Col lg={12} style={{padding: "0px 25px"}}>
          <Form.Group as={Row} style={{margin: "0px"}}>
          <Form.Label column sm={2}>
            Location:
          </Form.Label>
          <Col sm={10}>
            <Form.Control 
            type="text" 
            placeholder="Enter Location" 
            name="location"
            defaultValue={this.props.emailEdit.location}
            ref={element => this.location = element}
            onChange={()=>this.setState({locationErrorMsg:null})}
            maxLength="35"
            />
          </Col>
        </Form.Group>
        </Col>
      </Row>
      <Row>
      <Col lg={12} className={classes.errorMsg}>
        {this.state.locationErrorMsg!==null?this.state.locationErrorMsg:null}
        </Col>
      </Row>
      
      <Row>   
      <Col lg={12} style={{padding: "0px 25px"}}>
      <Form.Group as={Row} style={{margin: "0px"}}>
      <Form.Label column sm={2}>
        Type:
      </Form.Label>
      <Col sm={10}>
      <Form.Control as="select" id="type" name="type" onChange={()=>this.setState({typeErrorMsg:null})}>
      <option id="none">Select Company Type</option>
      <option value="IT_Company" id="IT_Company">IT Company</option>
      <option value="IT_Staffing" id="IT_Staffing">IT Staffing</option>
    </Form.Control>
      </Col>
    </Form.Group>
    </Col>
    </Row>
    <Row>
    <Col lg={12} className={classes.errorMsg}>
      {this.state.typeErrorMsg!==null?this.state.typeErrorMsg:null}
      </Col>
    </Row>

        <Row>
        <Col lg={12} style={{padding: "0px 25px"}}>
        <Form.Group as={Row} style={{margin: "0px"}}>
        <Form.Label column sm={2}>
          Email:
        </Form.Label>
        <Col sm={10}>
          <Form.Control
           type="text"
            placeholder="Enter Email" 
            defaultValue={this.props.emailEdit.email}
            name="email"
            defaultValue={this.props.emailEdit.email}
            ref={element => this.email = element}
            onChange={()=>this.setState({emailErrorMsg:null})}
            maxLength="42"
            />
        </Col>
      </Form.Group>
      </Col>
           </Row>

           <Row>
             <Col lg={12} className={classes.errorMsg}>
               {this.state.emailErrorMsg!==null?this.state.emailErrorMsg:null}
               </Col>
             </Row>
 
        <div className={classes.buttonDiv}>
         
            <button className={classes.closeSubmit} onClick={()=>this.closeAndReset()} style={{marginRight:"10px"}}>Close</button>
              <button className={classes.closeSubmit} style={{marginLeft:"10px"}} onClick={()=>this.addPartner(this.props.emailEdit.id)}>Submit</button>
       
        </div>
      </Modal>
          </div>
        )
        }
        else{
            return(
                <div></div>
            )
        }
    }
}

const mapStateToProps = state => {
  return {
    addpartnerLoader:state.addpartnerLoader,
    addpartnerList:state.addpartnerList,
    addpartnerTotalpages:state.addpartnerTotalpages,
    addpartnerError:state.addpartnerError,
    addpartnerNetissue:state.addpartnerNetissue
      
  }
}
const mapDispatchToProps = dispatch => {
  return {
      editPartner: (email,name,agency,location,type,id) => dispatch(actionTypes.editPartner(email,name,agency,location,type,id)),
       }
}

export default connect(mapStateToProps,mapDispatchToProps)(Editpartner);