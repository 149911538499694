export default {
  
  // QA
  // apiIpPort:"http://13.234.79.73:8080",
  // apiBaseUrl:"/partnernetwork/v1/api"

//production
// apiIpPort:"https://services.partner.innovationm.com",
// apiBaseUrl:"/partnernetwork/v1/api/" 

   //Staging
  //  apiIpPort:"http://13.127.87.149:8080",
  //  apiBaseUrl:"/partnernetwork/v1/api" 

  apiIpPort:process.env.REACT_APP_API_ENDPOINT,
  apiBaseUrl:process.env.REACT_APP_API_BASEURL, 
  
}