import React, { PureComponent } from 'react';
import {Row,Col,Button} from 'react-bootstrap';
import classes from './addpartner.module.css'
import { connect } from 'react-redux';
import Pagination from '../Pagination/Pagination';
import * as actionTypes from '../../Action/Index';
import ModeofInterview from './Modeofinterview'
import style from './customcheckbox.module.css';
import Loader from 'react-loader-advanced';
import loading from '../../Assets/Loader/loadert3.gif';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import sendMaillogo from '../../Assets/Loader/loaderT1.gif'
import Modaldiv from '../Modal/Modaldiv';
import Commonpopup from '../CommonPopup/Commonpopup';
import  Editpartner  from './Editpartner';
class Addpartner extends PureComponent{
  state={
    getpartnerList:[],
    modelShow:false,
    currentPage: 1,
    checkedCount:false,
    idArray:[],
    copied:false,
    mailer:false,
    trueIcon:false,
    deleteModel:false,
    deleteId:null,
    modeleditShow:false,
    emailEdit:null
  }

  toggleModel=()=>{
      this.setState({modelShow:!this.state.modelShow})
  }
  toggleEditModel=(emailEdit)=>{
    this.setState({modeleditShow:!this.state.modeleditShow,emailEdit:emailEdit})
}

  onPageChange = () => {
  this.state.currentPage=1
}
onLeftChange = () => {
   if (this.state.currentPage==1) {
       return
   }
   this.setState({
       currentPage: this.state.currentPage -1,
       mailer:false,
       idArray:[]
   },()=>{
    this.props.getPartnerList(this.state.currentPage);
   })
  
}
onRightChange = () => {
  
   if (this.props.getpartnerTotalpages == this.state.currentPage) {
       return
   }
   this.setState({
       currentPage: this.state.currentPage +1,
       mailer:false,
       idArray:[]
   },()=>{
    this.props.getPartnerList(this.state.currentPage);
   })
   
}

selectAllCheckbox=()=>{
    let name=document.getElementsByTagName("input");
    for(let i=0;i<name.length;i++){
        if(name[i].type=="checkbox")
          name[i].checked=true
    }
    this.setState({
        checkedCount:true,
        idArray:[]
    })
}

clearAllCheckbox=()=>{
    let name=document.getElementsByTagName("input");
    for(let i=0;i<name.length;i++){
        if(name[i].type=="checkbox")
          name[i].checked=false
    }
    this.setState({
        checkedCount:false,
        idArray:[]
    })
}

getAllInterviewId=()=>{
    var checkboxes = document.getElementsByTagName('input');
    this.state.idArray=[];
    for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox' && checkboxes[i].checked == true) {
            this.state.idArray.push(checkboxes[i].id);
        }
}
  if( this.state.idArray.length!=0){
    this.props.sendEmailtoPartner(this.state.idArray)
    
  }
  else{
alert("Please select a Partner");
    
  }
}

componentWillReceiveProps=(nextProps)=>{
    if(nextProps.getpartnerList.length!==0){
        this.setState({
            getpartnerList: nextProps.getpartnerList,
            checkedCount:false,
        })
    }
}

componentDidMount=()=>{
    this.props.getPartnerList(1);
}

copyToClipFun=()=>{
    this.setState({copied: true},()=>{
        setTimeout(() => {
            this.setState({copied: false})
          }, 1000);
    })
}

closedeleteModal=(id)=>{
    this.setState({deleteModel:!this.state.deleteModel,deleteId:id})
}

    render(){
       
        let partnerList=null;
        if(this.state.getpartnerList.length!=0){
            partnerList=this.state.getpartnerList.map(ind=>{
                return(
                    <tr key={ind.id}>
                    <td style={{width:"12%",wordBreak: "break-word"}}>
                    <label className={style.profile}>
            <div>
            <input type="checkbox" required id={ind.id}/>
            <span className={style.checkmark}></span>
           </div> 
        </label>
                    </td>
                    <td style={{width:"30%",wordBreak: "break-word"}}>{ind.companyName}</td>
                    <td style={{width:"20%",wordBreak: "break-word"}}>{ind.name}</td>
                    <td style={{width:"20%",wordBreak: "break-word"}}>{ind.location==null?"-":ind.location}</td>
                    <td style={{width:"20%",wordBreak: "break-word"}}>{ind.type==null?"-":ind.type}</td>
                   
                    <td style={{textAlign: "center",display: "flex",justifyContent: "flex-end"}}>
                    <i class="fas fa-pencil-alt" style={{color:"#fbb03b",marginTop:"12px",fontSize:"18px"}} title="Edit" onClick={()=>this.toggleEditModel(ind)}></i>&nbsp;&nbsp;&nbsp;&nbsp;
                    <i class="fas fa-trash-alt" style={{color:"#ff6666",marginTop:"12px",fontSize:"18px"}} title="Delete" onClick={()=>this.closedeleteModal(ind.id)}></i>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {
                        ind.submissionLink!=null?<CopyToClipboard text={ind.submissionLink} onCopy={()=>this.copyToClipFun()}>
                        <span><i class="far fa-copy" style={{color:"blue",marginTop:"12px",fontSize:"18px"}} title="Copy Link"></i></span>
                      </CopyToClipboard>:<span><i class="far fa-copy" style={{color:"grey",marginTop:"12px",fontSize:"18px"}} title="Link not Available"></i></span>
                    }
                    
                    &nbsp;&nbsp;
                    <span style={{height:"35px",width:"35px",marginTop:"10px"}}>
                    {this.props.sendMailLoader && this.state.idArray.includes(ind.id+"") ?<img src={sendMaillogo} title="Sending Email" width="30px"/>:
                (this.props.sendMailStatus && this.state.idArray.includes(ind.id+"")?<i class="fas fa-check-circle" title="Email Sent" style={{color:"green",fontSize:"22px"}}></i>:null)
                }
                    </span>
                    </td>
                    </tr>
                );
            })
        }else{
            partnerList=<tr><td colSpan="5" style={{textAlign:"center",padding:"10px 0px"}}>List of Partners will Show up here.</td></tr>
          }
let selectAll={
    width: "90px",
}

let checkedTrue=false;
var checkboxes = document.getElementsByTagName('input');
    for (var i = 0; i < checkboxes.length; i++) {
        if (checkboxes[i].type == 'checkbox' && checkboxes[i].checked == true) {
            checkedTrue=true
        }
}
 
        return(
            <div>
            <Row>
            <Col md={1}></Col>
            <Col md={10} style={{display:"flex",justifyContent:"space-between"}}>
                <div className={classes.heading}>
                <h3>IT-Partners</h3>
                </div>

                <div className={classes.heading}>
                <h4 onClick={()=>this.toggleModel()}><i class="fas fa-plus" ></i>&nbsp;Add</h4>
                </div>
            </Col>
        </Row>
        <Loader show={this.props.getpartnerLoader} message={<img width="150px" src={loading} />}
        foregroundStyle={{ color: '#00a99d' }}
        backgroundStyle={{ backgroundColor: 'white' }}
       
        >
        <Row>
        <Col md={1}></Col>
        <Col md={10} className="table-responsive">
            
            <table className={classes.scheduleTbl}>
            <thead>
            <tr>
              <th>
              {this.state.checkedCount==true?
                <button type="button"  style={selectAll} className={classes.btnWarning} onClick={()=>this.clearAllCheckbox()}>Clear All</button>
           :
              <button type="button"  style={selectAll} className={classes.btnWarning} onClick={()=>this.selectAllCheckbox()}>Select All</button>
                }
              </th>
              <th>
              Company name
              </th>
              <th>
              Name
              </th>
              <th>
              Location
              </th>
              <th>
              Type
              </th>
              <th style={{display:"flex",justifyContent:"center"}}>
              <button  style={selectAll} className={classes.btnWarning} onClick={()=>this.getAllInterviewId()}>Send Email</button>
              </th>
            </tr>
          </thead>
                <tbody id="jobprofile">
                
                {partnerList}


                </tbody>
            </table>
        </Col>
        <Col md={1}></Col>
    </Row>

    <div className={classes.footer}>
    <Row>
                                       
                                        <Col md={12}>
                                            {this.props.getpartnerTotalpages > 1 ?
                                                <Pagination
                                                    activePage={this.state.currentPage}
                                                    pageRangeDisplayed={this.props.getpartnerTotalpages}
                                                    onPageChange={this.onPageChange}
                                                    onLeftChange={this.onLeftChange}
                                                    onRightChange={this.onRightChange}
                                                />
                                                : null}
                                        </Col>


                                    </Row>
    </div>
</Loader>
     <ModeofInterview onPageChange={this.onPageChange} show={this.state.modelShow} toggleModel={this.toggleModel}/>
     <Modaldiv show={this.state.copied} />
     <Commonpopup show={this.state.deleteModel} 
     deleteId={this.state.deleteId}
      closedeleteModal={()=>this.closedeleteModal()}
      deletePartner={this.props.deletePartner}
      />
    {
        this.state.modeleditShow==true?<Editpartner onPageChange={this.onPageChange} show={this.state.modeleditShow} emailEdit={this.state.emailEdit} toggleModel={this.toggleEditModel}/>:null
      
    }
 
</div>
        )
    }
    
}

const mapStateToProps = state => {
   
    return {
        getpartnerLoader:state.getpartnerLoader,
        getpartnerList:state.getpartnerList,
        getpartnerTotalpages:state.getpartnerTotalpages,
        getpartnerError:state.getpartnerError,
        getpartnerNetissue:state.getpartnerNetissue,

        sendMailLoader:state.sendMailLoader,
        sendMailStatus:state.sendMailStatus
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getPartnerList: (page) => dispatch(actionTypes.getPartnerList(page)),
        sendEmailtoPartner:(arr)=>dispatch(actionTypes.sendEmailtoPartner(arr)),
        deletePartner:(id)=>dispatch(actionTypes.deletePartner(id))
         }
  }
  export default connect(mapStateToProps, mapDispatchToProps)(Addpartner);