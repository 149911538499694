import React, { PureComponent } from 'react';
import {Row,Col,Button,Table} from 'react-bootstrap';
import classes from './formtype.module.css'
import {NavLink, Link, Redirect, withRouter } from 'react-router-dom';
import {SimpleButton,HoverButton} from '../Buttons/Buttons'
class Formtype extends PureComponent{
  

    render(){
        return(
            <div>
            <Row>
            <Col md={1}></Col>
            <Col md={10} style={{display:"flex",justifyContent:"space-between"}}>
                <div className={classes.heading}>
                <h3>Edit Form</h3>
                </div>

                <div className={classes.heading}>
                <h4 onClick={()=>this.props.history.push('/createform/editform',{type:"Editform",
                fieldValue:""})}><i class="fas fa-plus" ></i>&nbsp;Add Field</h4>
                </div>
            </Col>
        </Row>

        <Row>
        <Col md={1}></Col>
        <Col md={10} className="table-responsive">
        <table className={classes.scheduleTbl}>
        <thead>
          <tr>
            <th>Heading</th>
            <th>Response Type</th>
            <th colSpan="2" style={{textAlign:"center"}}>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Please confirm if there are availabe resources for given Technology/Resources.</td>
            <td>Checkbox</td>
            <td style={{textAlign:"center"}}>
            <SimpleButton width="80px" fun={()=>this.props.history.push('/createform/editform',{type:"Editform",
            fieldValue:"Please confirm if there are availabe resources for given Technology/Resources."})}>
            Edit
           </SimpleButton>
            </td>
            <td style={{textAlign:"center"}}>
            <SimpleButton width="80px" color="#ff6666" fun={()=>alert("Delete")}>
            Delete
           </SimpleButton>
            </td>
          </tr>
          <tr>
          <td>Do you have any additional skill available.?</td>
          <td>TextArea</td>
            <td style={{textAlign:"center"}}>
             <SimpleButton width="80px" fun={()=>this.props.history.push('/createform/editform',{type:"Editform",
             fieldValue:"Please confirm if there are availabe resources for given Technology/Resources."})}>
            Edit
           </SimpleButton>
            </td>
            <td style={{textAlign:"center"}}>
            <SimpleButton width="80px" color="#ff6666" fun={()=>alert("Delete")}>
            Delete
           </SimpleButton>
            </td>
          </tr>
          <tr>
          <td>Do you have any additional feedback.?</td>
          <td>TextArea</td>
            <td style={{textAlign:"center"}}>
             <SimpleButton width="80px" fun={()=>this.props.history.push('/createform/editform',{type:"Editform",
             fieldValue:"Please confirm if there are availabe resources for given Technology/Resources."})}>
            Edit
           </SimpleButton>
            </td>
            <td style={{textAlign:"center"}}>
            <SimpleButton width="80px" color="#ff6666" fun={()=>alert("Delete")}>
            Delete
           </SimpleButton>
            </td>
          </tr>
        </tbody>
      </table>
        </Col>
        <Col md={1}></Col>
    </Row>
      
</div>
        )
    }
    
}

export default withRouter(Formtype)