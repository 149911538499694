import * as actionTypes from './ActionTypes';
import {loginRequest,getRequest,postRequest,putRequest} from '../Helper/ApiHit'
import moment from 'moment'
export const getLogin = (email,password)=>{
    return (dispatch)=>{
           dispatch({type:actionTypes.LOGIN_INITIAL});
           loginRequest('/employer/signin',{
                "email":email,
                "password":password
           }).then(res=>{
            if(res.data.responseStatus.statusCode ===0 ){
                localStorage.setItem('JitToken',res.headers.authorization)
                return dispatch({
                    type:actionTypes.LOGIN_SUCCESS,
                    payload :true
                })    
            }
            else{
                 localStorage.setItem('jitLoginStatus','')
                return dispatch({
                    type:actionTypes.LOGIN_FAIL,
                    payload :res.data.responseData.message,
                })
            }
           })
           .catch(error =>{
             localStorage.setItem('jitLoginStatus','')
            return dispatch({
                type:actionTypes.LOGIN_FAIL,
                payload :"Server Error,Try Again"
            })
           })
    }
}


export const getPartnerList = (page)=>{
    return (dispatch)=>{
           dispatch({type:actionTypes.GET_PARTNER_LIST_INITIAL});
           getRequest(`/employer/getPartners?page=${page}&limit=7`)
           .then(res=>{
           
            if(res.data.responseStatus.statusCode ===0 ){
                return dispatch({
                    type:actionTypes.GET_PARTNER_LIST_SUCCESS,
                    payload :res.data.responseData.getPartnerDetailList,
                    totalPages:res.data.responseData.commonPaginationResponse.totalPages
                })    
            }
            else{
                return dispatch({
                    type:actionTypes.GET_PARTNER_LIST_FAIL,
                    errorMsg:res.responseData.message
                   
                })
            }
           })
           .catch(error =>{
                console.log(error)
            return dispatch({
                type:actionTypes.GET_PARTNER_LIST_NETWORK_ERROR,
                errorMsg:"Network issues."
            })
           })
    }
}

export const addPartner = (email,name,agency,location,type) => {
    return (dispatch) => {
        dispatch({type:actionTypes.ADD_PARTNER_LIST_INITIAL});
            postRequest('/employer/addPartner', {
                "email":email,
                "name": name,
                "companyType": type,
                "location": location,
                "companyName":agency,
               
            })
            .then(res=>{
                if(res.data.responseStatus.statusCode === 0){
                    dispatch({type:actionTypes.GET_PARTNER_LIST_INITIAL});
           getRequest(`/employer/getPartners?page=${1}&limit=7`)
           .then(res=>{
           
            if(res.data.responseStatus.statusCode ===0 ){
                return dispatch({
                    type:actionTypes.GET_PARTNER_LIST_SUCCESS,
                    payload :res.data.responseData.getPartnerDetailList,
                    totalPages:res.data.responseData.commonPaginationResponse.totalPages
                })    
            }
            else{
                return dispatch({
                    type:actionTypes.GET_PARTNER_LIST_FAIL,
                    errorMsg:res.responseData.message
                   
                })
            }
           })
           .catch(error =>{
                console.log(error)
            return dispatch({
                type:actionTypes.GET_PARTNER_LIST_NETWORK_ERROR,
                errorMsg:"Network issues."
            })
           })
                    return dispatch({
                        type:actionTypes.ADD_PARTNER_LIST_SUCCESS,
                        payload:true
                    })
                }
               else{
                return dispatch({
                    type:actionTypes.ADD_PARTNER_LIST_FAIL,
                    payload: res.data.responseData.message
                })
               }
            })
            .catch(err=>{
                console.log(err)
                return dispatch({
                    type: actionTypes.ADD_PARTNER_LIST_FAIL,
                    payload: err.message
                })
            })
    }
}


export const getSubmissionDetailsListMethod = (page,start,end)=>{
    return (dispatch)=>{
           dispatch({type:actionTypes.GET_SUBMISSION_DETAILS_INITIAL});
           postRequest(`/employer/getFormSubmissionList?page=${page}&limit=7`,{
            "startDate":start==null?"":moment(start).format("YYYY-MM-DD"),
            "endDate":end==null?"":moment(end).format("YYYY-MM-DD")
           })
           .then(res=>{
           
            if(res.data.responseStatus.statusCode ===0 ){
                return dispatch({
                    type:actionTypes.GET_SUBMISSION_DETAILS_SUCCESS,
                    payload :res.data.responseData.formSubmissionListDetail,
                    totalPages:res.data.responseData.commonPaginationResponse.totalPages
                })    
            }
            else{
                return dispatch({
                    type:actionTypes.GET_SUBMISSION_DETAILS_FAIL,
                    errorMsg:res.data.responseData.message
                   
                })
            }
           })
           .catch(error =>{
                console.log(error)
            return dispatch({
                type:actionTypes.GET_SUBMISSION_DETAILS_NETWORK_ERROR,
                errorMsg:"Network issues."
            })
           })
    }
}


export const getFormsList = (page)=>{
    return (dispatch)=>{
           dispatch({type:actionTypes.GET_FORM_LIST_INITIAL});
           getRequest(`/employer/getFormsList?page=${page}&limit=7`)
           .then(res=>{
           
            if(res.data.responseStatus.statusCode ===0 ){
                return dispatch({
                    type:actionTypes.GET_FORM_LIST_SUCCESS,
                    payload :res.data.responseData.getPartnerDetailList,
                    totalPages:res.data.responseData.commonPaginationResponse.totalPages
                })    
            }
            else{
                return dispatch({
                    type:actionTypes.GET_FORM_LIST_FAIL,
                    errorMsg:res.responseData.message
                   
                })
            }
           })
           .catch(error =>{
                console.log(error)
            return dispatch({
                type:actionTypes.GET_FORM_LIST_NETWORK_ERROR,
                errorMsg:"Network issues."
            })
           })
    }
}


export const getFormSubmissionRequestDetail = (id)=>{
    return (dispatch)=>{
           dispatch({type:actionTypes.GET_FORM_SUBMISSION_DETAILS_INITIAL});
           postRequest(`/employer/getFormSubmissionRequestDetail`,{
               "id":id
           })
           .then(res=>{
           
            if(res.data.responseStatus.statusCode ===0 ){
                return dispatch({
                    type:actionTypes.GET_FORM_SUBMISSION_DETAILS_SUCCESS,
                    payload :res.data.responseData.formDetails,
                    partnerName:res.data.responseData.partnerName,
                    submissionId:res.data.responseData.submissionId
                })    
            }
            else{
                return dispatch({
                    type:actionTypes.GET_FORM_SUBMISSION_DETAILS_FAIL,
                    errorMsg:res.responseData.message
                   
                })
            }
           })
           .catch(error =>{
                console.log(error)
            return dispatch({
                type:actionTypes.GET_FORM_SUBMISSION_DETAILS_NETWORK_ERROR,
                errorMsg:"Network issues."
            })
           })
    }
}


export const saveFormSubmissionResponse = (formData,subId)=>{
   
    return (dispatch)=>{
           dispatch({type:actionTypes.GET_SAVE_FORM_SUBMISSION_RESPONSE_INITIAL});
           postRequest(`/employer/saveFormSubmissionResponse`,{
            "partnerFormSubmissionResponseList":formData,
            "submissionId":subId
           })
           .then(res=>{
           
            if(res.data.responseStatus.statusCode ===0 ){
                return dispatch({
                    type:actionTypes.GET_SAVE_FORM_SUBMISSION_RESPONSE_SUCCESS,

                })    
            }
            else{
                return dispatch({
                    type:actionTypes.GET_SAVE_FORM_SUBMISSION_RESPONSE_FAIL,

                   
                })
            }
           })
           .catch(error =>{
                console.log(error)
            return dispatch({
                type:actionTypes.GET_SAVE_FORM_SUBMISSION_RESPONSE_NETWORK_ERROR,

            })
           })
    }
}

export const resetAllState=()=>{
    return (dispatch)=>{
        dispatch({type:actionTypes.RESER_ALL_STATE});
    }
}

export const sendEmailtoPartner=(email)=>{
    return (dispatch)=>{
        dispatch({type:actionTypes.SEND_EMAIL_TO_PARTNER_INITIAL});
        postRequest(`/employer/sendFormSubmissionEmail`,{
         "partnersId":email
        })
        .then(res=>{
         if(res.data.responseStatus.statusCode ===0 ){
             return dispatch({
                 type:actionTypes.SEND_EMAIL_TO_PARTNER_SUCCESS,
             })    
         }
         else{
             return dispatch({
                 type:actionTypes.SEND_EMAIL_TO_PARTNER_FAIL,
             })
         }
        })
        .catch(error =>{
             console.log(error)
         return dispatch({
             type:actionTypes.SEND_EMAIL_TO_PARTNER_NETWORK_ERROR,
         })
        })
 }
}
 
export const deletePartner=(id)=>{
    return (dispatch)=>{
        dispatch({type:actionTypes.DELETE_EMAIL_OF_PARTNER_INITIAL});
        putRequest(`/employer/deletePartner?id=${id}`)
        .then(res=>{
         if(res.data.responseStatus.statusCode ===0 ){

            dispatch({type:actionTypes.GET_PARTNER_LIST_INITIAL});
            getRequest(`/employer/getPartners?page=${1}&limit=7`)
            .then(res=>{
            
             if(res.data.responseStatus.statusCode ===0 ){
                 return dispatch({
                     type:actionTypes.GET_PARTNER_LIST_SUCCESS,
                     payload :res.data.responseData.getPartnerDetailList,
                     totalPages:res.data.responseData.commonPaginationResponse.totalPages
                 })    
             }
             else{
                 return dispatch({
                     type:actionTypes.GET_PARTNER_LIST_FAIL,
                     errorMsg:res.responseData.message
                    
                 })
             }
            })
            .catch(error =>{
                 console.log(error)
             return dispatch({
                 type:actionTypes.GET_PARTNER_LIST_NETWORK_ERROR,
                 errorMsg:"Network issues."
             })
            })

             return dispatch({
                 type:actionTypes.DELETE_EMAIL_OF_PARTNER_SUCCESS,
             })    
         }
         else{
             return dispatch({
                 type:actionTypes.DELETE_EMAIL_OF_PARTNER_FAIL,
             })
         }
        })
        .catch(error =>{
             console.log(error)
         return dispatch({
             type:actionTypes.DELETE_EMAIL_OF_PARTNER_NETWORK_ERROR,
         })
        })
 }
}

export const editPartner = (email,name,agency,location,type,id) => {
    return (dispatch) => {
        dispatch({type:actionTypes.ADD_PARTNER_LIST_INITIAL});
        putRequest('/employer/editPartner', {
                "companyName":agency,
"companyType":type,
"email":email,
"location":location,
"name":name,
"partnerId":id
            })
            .then(res=>{
                if(res.data.responseStatus.statusCode === 0){
                    dispatch({type:actionTypes.GET_PARTNER_LIST_INITIAL});
           getRequest(`/employer/getPartners?page=${1}&limit=7`)
           .then(res=>{
           
            if(res.data.responseStatus.statusCode ===0 ){
                return dispatch({
                    type:actionTypes.GET_PARTNER_LIST_SUCCESS,
                    payload :res.data.responseData.getPartnerDetailList,
                    totalPages:res.data.responseData.commonPaginationResponse.totalPages
                })    
            }
            else{
                return dispatch({
                    type:actionTypes.GET_PARTNER_LIST_FAIL,
                    errorMsg:res.responseData.message
                   
                })
            }
           })
           .catch(error =>{
                console.log(error)
            return dispatch({
                type:actionTypes.GET_PARTNER_LIST_NETWORK_ERROR,
                errorMsg:"Network issues."
            })
           })
                    return dispatch({
                        type:actionTypes.ADD_PARTNER_LIST_SUCCESS,
                        payload:true
                    })
                }
               else{
                return dispatch({
                    type:actionTypes.ADD_PARTNER_LIST_FAIL,
                    payload: res.data.responseData.message
                })
               }
            })
            .catch(err=>{
                console.log(err)
                return dispatch({
                    type: actionTypes.ADD_PARTNER_LIST_FAIL,
                    payload: err.message
                })
            })
    }
}