import React from 'react';
import {SimpleButton,HoverButton} from '../Buttons/Buttons'
import {Row,Col, Form, Button,Modal} from 'react-bootstrap';

const Modaldiv = (props) => {
    return (
        <Modal
        show={props.show}
        
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
      <div style={{padding: "15px"}}>
       <div style={{textAlign:"center",fontWeight:"bold"}}>Please select atleast one skill.</div><br/>
       <span style={{display:"flex",justifyContent:"center"}}>
       <SimpleButton width="80px" color="#8cc63f" fun={()=>props.hideModel()}>
       OK
       </SimpleButton>
</span>
       </div>
      </Modal>
   
    );
}

export default Modaldiv;