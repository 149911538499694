import React, { PureComponent } from 'react';
import {Row,Col,Form} from 'react-bootstrap';
import classes from './usersubmissionform.module.css'
import {SimpleButton,HoverButton} from '../Buttons/Buttons'
import Loader from 'react-loader-advanced';
import loading from '../../Assets/Loader/loadert3.gif';
import style from './customcheckbox.module.css';
import logo from '../../Assets/Logo_InnovationM/1x/InnovationM Logomdpi.png'
import { connect } from 'react-redux';
import * as actionTypes from '../../Action/Index';
import queryString, { parse } from 'query-string';
import UserSubmissionSuccess from '../UserSubmissionSuccess/UserSubmissionSuccess'
import ErrorModel from './ErrorModel'
class UsersubmissionForm extends PureComponent{
               state={
                 loader:false,
                 skillList:[],
                 getFormsSubmissionDetailsList:[],
                 token:"",
                 idArray:[],
                 selectError:false
               }

               componentWillMount=()=>{
                let url=this.props.location.search;
                if(url!==''){
                   let params = queryString.parse(url);
                  let token=params.id
                  this.setState({token:token})
               }
            }

               componentDidMount=()=>{
                this.props.getFormSubmissionRequestDetail(this.state.token)
               }

               componentWillReceiveProps=(nextProp)=>{

                if(nextProp.saveFormStatus==true){
                  this.props.history.push('/usersubmissionsuccess',{
                    url:`usersubmissionform?id=${this.state.token}`,
                    responseMsg:"Thank you for the information. We will get back to you once we have opportunities."
                  })
                  this.props.resetAllState()
                }
               else if(nextProp.getFormsSubmissionDetailsList.length!=0){
                   this.setState({getFormsSubmissionDetailsList:nextProp.getFormsSubmissionDetailsList,                                     
                  })
                 }
                  if(nextProp.getFormsSubmissionDetailsNetissue){
                  this.props.history.push('/usersubmissionsuccess',{
                    url:null,
                    responseMsg:"Sorry! You cannot re-submit form."
                  })
                 }
               }

               getAllSkillId=()=>{

                var checkboxes = document.getElementsByTagName('input');
                this.state.idArray=[];
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].type == 'checkbox' && checkboxes[i].checked == true) {
                        this.state.idArray.push(parseInt(checkboxes[i].id));
                    }
            }
              if( this.state.idArray.length!=0){
                      let arr=[
                        {
                          "optionsId":this.state.idArray,
                          "questionId":1,
                          "responseMessage":null
                        },
                        {
                          "optionsId":[],
                          "questionId":2,
                          "responseMessage":document.getElementById("textArea").value
                        }
                      ]
                     this.props.saveFormSubmissionResponse(arr,this.props.submissionId);
                    // console.log(arr)
                     this.state.idArray=[];

                 } 
              else{
                this.setState({selectError:true})
              }
            }
            hideModel=()=>{
              this.setState({selectError:false})
            }

    render(){

      let list=null;
      if(this.state.getFormsSubmissionDetailsList.length!=0){
        list=this.state.getFormsSubmissionDetailsList.map(ind=>{
          return(
            <Col md={12} className={classes.colborder}>
            <span className={classes.heading} id="questionid" snjdnj={ind.questionId}>{ind.heading}</span>
            <br/><br/>
            { ind.responseType=="CHECKBOX"?
              ind.optionsValueList.map(index=>{
                return(
                  <div>
                  <label className={style.profile}>
                  <div>
                  <input type="checkbox" required id={index.id}/>
                  <span className={style.checkmark}></span>
                  </div> 
                  </label>
                  <span style={{marginLeft:"35px",fontSize:"18px"}}>{index.value}</span>
                  </div>
                )
              })
              : <Form.Group controlId="exampleForm.ControlTextarea2">
              <Form.Control as="textarea" id="textArea" rows="3" />
              </Form.Group>
            }
              </Col>


           
          )
        })
      }
       
        return(
            <div>
           
            <Row>
            <div className={classes.navbar}>
            <img src={logo} style={{width:"200px",height:"50px"}}/>
          </div>
            </Row>

            <Loader show={this.props.getFormsSubmissionDetailsLoader} message={<img width="150px" src={loading} />}
            foregroundStyle={{ color: '#00a99d' }}
            backgroundStyle={{ backgroundColor: 'white' }}
            
            >
            <Row>
            <Col md={2} sm={1}></Col>
            <Col md={8} sm={10}>
            <Form>
            <Row>
              <Col md={12} className={classes.makeincenter}>
                <div className={classes.heading2}>
                  {this.props.partnerName}
                </div>
               </Col>
            </Row>
              {list}
            <Row>
              
            </Row>

        <div className={classes.makeincentersubmit}>
        <SimpleButton width="117px" padding="7px 0px" color="#8cc63f" fun={()=>this.getAllSkillId()}>
        Submit
        </SimpleButton>
        </div>
        <br/>
        </Form>
            </Col>

            <Col md={2} sm={1} ></Col>
        </Row>
        </Loader>

        <ErrorModel show={this.state.selectError} hideModel={this.hideModel}/>
</div>
        )
    }
    
}

const mapStateToProps = state => {
  return {
      getFormsSubmissionDetailsLoader:state.getFormsSubmissionDetailsLoader,
      getFormsSubmissionDetailsList:state.getFormsSubmissionDetailsList,
      getFormsSubmissionDetailsError:state.getFormsSubmissionDetailsError,
      getFormsSubmissionDetailsNetissue:state.getFormsSubmissionDetailsNetissue,
      partnerName:state.partnerName,
      submissionId:state.submissionId,

      getSaveError:state.getSaveError,
      saveFormStatus:state.saveFormStatus
  }
}

const mapDispatchToProps = dispatch => {
  return {
      getFormSubmissionRequestDetail: (id) => dispatch(actionTypes.getFormSubmissionRequestDetail(id)),
      saveFormSubmissionResponse:(fdata,sid)=>dispatch(actionTypes.saveFormSubmissionResponse(fdata,sid)),
      resetAllState:()=>dispatch(actionTypes.resetAllState()),
      
       }
}
export default connect(mapStateToProps, mapDispatchToProps)(UsersubmissionForm);