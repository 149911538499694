import React, { Component } from 'react'
import {Row,Col, FormControl, Button,Modal, ModalBody} from 'react-bootstrap';
import classes from './viewresponse.module.css'
export class ViewResponse extends Component {
   

    render() {

let skillList=null;
  if(this.props.partnerSubmissionResponseModel!=null){
   skillList=this.props.partnerSubmissionResponseModel.responseOption.map(ind=>
    <Col style={{boxShadow: "0px 0px 5px 0px",margin:"10px",textAlign:"center"}} md={4}>{ind}</Col>
    )
  }
        return (
          <>
          <Modal centered show={this.props.show} backdrop= "false" >
          <Modal.Body >
          <h4 className={classes.headingClass}>
          Response
          </h4>
        
          <Row className={classes.makeincenter}>   
          
          {
            skillList
         }


        </Row>
       {
        this.props.partnerSubmissionResponseModel==null?"":<Row className={classes.makeincenter}> 
        { 
          this.props.partnerSubmissionResponseModel.additionalMessage==""?"": <Col md={9}>
          Additional Skill: <span style={{wordBreak:"break-all"}}>{this.props.partnerSubmissionResponseModel.additionalMessage}</span>
          </Col>
        }   
       </Row>
       }
        

        </Modal.Body>
 
        <div className={classes.buttonDiv}>
         
            <button className={classes.closeSubmit} onClick={()=>this.props.toggleModel()}>Close</button>
            
        </div>
      
      </Modal>
          </>
        )
    }
}

export default ViewResponse;
